.footer-main {
    margin-top: 0;
    padding-top: 10rem;
    min-height: 300px;

    .footer-logo {
        text-align: center;
    }

    ul {
        list-style-type: none;
        padding-left: 0;

        li {
            margin-bottom: 1rem;
            text-align: center;

            a {
                color: $white;

                .material-icons-outlined {
                    color: $third;
                    margin-right: 1rem;
                }

                &:hover,
                &:focus {
                    color: $white;
                }
            }
        }
    }

    @include media-breakpoint-up(sm) {
    }
    @include media-breakpoint-up(md) {
        .footer-logo {
            text-align: left;
        }

        ul {
            li {
                text-align: right;
            }
        }
    }
    @include media-breakpoint-up(lg) {
    }
    @include media-breakpoint-up(xl) {
    }
    @include media-breakpoint-up(xxl) {
    }
}
