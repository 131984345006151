@import "variables";

@import "bootstrap/scss/bootstrap";
@import "./shared/footer.scss";
@import "./shared/footer-main.scss";

body {
    font-family: "Montserrat", sans-serif;
    overflow-x: hidden;
    font-optical-sizing: auto;
    background-color: #04090d;
    font-style: normal;
    font-weight: 400;
    font-size: 20px;

    ::selection {
        // background-color: #16637d;
        // color: #f8fbf4;
        background-color: #f8fbf4;
        color: black;
    }

    main {
        overflow-x: hidden;
    }
}

.navbar-toggler {
    padding: 0.25rem 0.35rem 0.25rem 0;
    min-width: 38px;
    min-height: 48px;
    position: relative;
    border: none;

    &:focus,
    &:hover {
        background-color: transparent;
        outline: initial;
        box-shadow: initial;
    }

    .icon-bar {
        display: block;
        width: 24px;
        height: 2px;
        border-radius: 1px;
        background-color: $primary;
        transition: all 0.15s;

        &:nth-child(1) {
            transform: rotate(45deg);
            transform-origin: 10% 10%;
        }

        &:nth-child(2) {
            margin-top: 4px;
            opacity: 0;
        }

        &:nth-child(3) {
            margin-top: 4px;
            transform: rotate(-45deg);
            transform-origin: 14% 90%;
        }
    }

    &.collapsed {
        .icon-bar {
            &:nth-child(1),
            &:nth-child(3) {
                transform: rotate(0);
            }
            &:nth-child(2) {
                opacity: 1;
            }
        }
    }
}

.navbar-toggler.collapsed span .navbar-brand {
    padding: 0;

    .navbar-brand-placeholder {
        height: 32px;
        font-size: 19px;
    }
}

.section-title {
    position: relative;
    z-index: 1;
    display: inline-block;
    min-height: 100px;
    padding: 1.6rem 0 1.6rem 2.4rem;

    &:hover {
        &::before {
            width: 100%;
            transition: width 0.25s ease-in-out;
        }
    }

    &::before {
        position: absolute;
        content: "";
        width: 218px;
        height: 100%;
        min-height: 100px;
        z-index: -1;
        top: 50%;
        left: 0;
        border-radius: 30px;
        background: linear-gradient(90deg, rgba(#0e2038, 1), rgba(#166681, 1));
        opacity: 0;
        transition: width 0.2s ease-in-out;
    }

    .section-title-long {
        font-size: 20px;
        margin-top: 0;
        opacity: 0;
        max-width: 487px;
    }

    &.section-title-center {
        padding-left: 0 !important;
        padding-right: 0 !important;
    }

    h2 {
        font-size: 40px;
        font-weight: 800;
        margin-bottom: 0;
        margin-top: 0.5rem;
        opacity: 0;
    }

    &.fade-in-left {
        .section-title-long {
            animation-name: fadeInLeft;
            animation-fill-mode: forwards;
            animation-duration: 0.35s;
            animation-delay: 0.35s;
        }

        h2 {
            animation-name: fadeInLeft;
            animation-fill-mode: forwards;
            animation-duration: 0.35s;
            animation-delay: 0.7s;
        }

        &::before {
            animation-name: fadeInLeftForBefore;
            animation-fill-mode: forwards;
            animation-duration: 0.35s;
        }
    }

    &.text-center {
        &.fade-in-left {
            &::before {
                animation-name: initial;
                animation-fill-mode: initial;
                animation-duration: initial;
            }
        }

        &::before {
            opacity: 1;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
        }
    }
}

.fw-bold {
    font-weight: 800 !important;
}

.btn-primary {
    line-height: 27px;
    font-size: 20px;
}

.btn {
    min-width: 224px;
}

.bg-gradient {
    background: linear-gradient(90deg, #0e2038, #166681) !important;
}

.bg-gradient-2 {
    background: linear-gradient(0deg, #0e2038, #166681) !important;
}

.modal-content {
    background: #0d2039 !important;
}

@keyframes fadeInLeft {
    0% {
        opacity: 0;
        transform: translateX(-20px);
    }
    100% {
        opacity: 1;
        transform: translateX(0);
    }
}

@keyframes fadeInLeftForBefore {
    0% {
        opacity: 0;
        transform: translate(-20px, -50%);
    }
    100% {
        opacity: 1;
        transform: translate(0, -50%);
    }
}

.glowing-text {
    animation: blink 3s infinite alternate ease-in-out;
}

@keyframes blink {
    0% {
        text-shadow: 0 0 5px rgba(255, 255, 255, 0.3);
    }
    25% {
        text-shadow: 0 0 8px rgba(255, 255, 255, 0.6);
    }
    50% {
        text-shadow: 0 0 3px rgba(255, 255, 255, 0.3);
    }
    75% {
        text-shadow: 0 0 6px rgba(255, 255, 255, 0.4);
    }
    100% {
        text-shadow: 0 0 5px rgba(255, 255, 255, 0.5);
    }
}
